// PERSISTED DATA
export const SAVE_PERSISTED_DATA = 'SAVE_PERSISTED_DATA';
export const DELETE_PERSISTED_DATA = 'DELETE_PERSISTED_DATA';

export const SAVE_API_RESPONSE = 'SAVE_API_RESPONSE';
export const DELETE_API_RESPONSE = 'DELETE_API_RESPONSE';

// USER
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_SWITCH = 'USER_SWITCH';

export const login_action_types = {
	// CHANGE_PASSWORD: 'CHANGE_PASSWORD',
	CHANGE_EMAIL: 'CHANGE_EMAIL',
	CHANGE_STATUS: 'CHANGE_STATUS',
	FORGOT_PASSWORD_CHANGE_EMAIL: 'FORGOT_PASSWORD_CHANGE_EMAIL',
	// RESET_PASSWORD_CHANGE_PWD: 'RESET_PASSWORD_CHANGE_PWD',
	// RESET_PASSWORD_CHANGE_PWD_CONFIRM: 'RESET_PASSWORD_CHANGE_PWD_CONFIRM',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
	STORE_USER_DETAILS: 'STORE_USER_DETAILS',
	SET_WIZPAY_URL: 'SET_WIZPAY_URL',
} as const;

export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const SHOW_INITIAL_STATE = 'SHOW_INITIAL_STATE';

export const UPDATE_BUYER = 'UPDATE_BUYER';
export const TOTAL_BUYERS = 'TOTAL_BUYERS';
export const UPDATE_BUYER_CART = 'UPDATE_BUYER_CART';
export const SHOW_BUYER_TOAST = 'SHOW_BUYER_TOAST';
export const UPDATE_BUYER_PRICE_LIST = 'UPDATE_BUYER_PRICE_LIST';
export const UPDATE_CATALOG = 'UPDATE_CATALOG';
export const CREATE_DOCUMENT_SELECTED_BUYER = 'CREATE_DOCUMENT_SELECTED_BUYER';
export const CREATE_DOCUMENT_TYPE = 'CREATE_DOCUMENT_TYPE';
export const UPDATE_DOCUMENT_DATA = 'UPDATE_DOCUMENT_DATA';
export const SET_DOCUMENT_DATA = 'SET_DOCUMENT_DATA';
export const TOGGLE_EDIT_QUOTE = 'TOGGLE_EDIT_QUOTE';
export const TOGGLE_EDIT_PENDING_ORDER = 'TOGGLE_EDIT_PENDING_ORDER';
export const SHOW_DOCUMENT_TOAST = 'SHOW_TOAST';
export const SHOW_DOCUMENT_TOAST_MESSAGE = 'SHOW_TOAST_MESSAGE';
export const SHOW_DOCUMENT_ALERT = 'SHOW_ALERT';
export const UPDATE_DASHBOARD_OPTIONS = 'UPDATE_DASHBOARD_OPTIONS';

export const UPDATE_PERMISSIONS = 'UPDATE_PERMISSIONS';
export const REFETCH_PERMISSIONS = 'REFETCH_PERMISSIONS';
export const TASK_UPDATED = 'TASK_UPDATED';
export const TASK_DISMISSED = 'TASK_DISMISSED';
export const SET_TASKS = 'SET_TASKS';
export const SHOW_NOTIFICATION_FEEDBACK = 'SHOW_NOTIFICATION_FEEDBACK';
export const catalog_data_action_types = {
	UPDATE_LOADER: 'UPDATE_LOADER',
	UPDATE_CATALOG_DATA: 'UPDATE_CATALOG_DATA',
};
export const catalog_mode_action_types = {
	UPDATE_CATALOG_MODE: 'UPDATE_CATALOG_MODE',
	UPDATE_PRODUCT_LENGTH: 'UPDATE_PRODUCT_LENGTH',
	ADD_SINGLE_PRODUCT: 'ADD_SINGLE_PRODUCT',
	ADD_MULTIPLE_PRODUCTS: 'ADD_MULTIPLE_PRODUCTS',
	REMOVE_SINGLE_PRODUCT: 'REMOVE_SINGLE_PRODUCT',
	REMOVE_MULTIPLE_PRODUCTS: 'REMOVE_MULTIPLE_PRODUCTS',
	SET_PRODUCTS: 'SET_PRODUCTS',
	REMOVE_ALL_PRODUCTS: 'REMOVE_ALL_PRODUCTS',
	SET_SELECTED_PRICELIST: 'SET_SELECTED_PRICELIST',
	SET_SELECTED_SORT: 'SET_SELECTED_SORT',
	RESET_CATALOG_MODE: 'RESET_CATALOG_MODE',
	SET_EDIT_MODE: 'SET_EDIT_MODE',
	SET_EDIT_CATALOG_ID: 'SET_EDIT_CATALOG_ID',
	SET_IS_EDIT_FETCHED: 'SET_IS_EDIT_FETCHED',
	SET_EDIT_CATALOG_DATA: 'SET_EDIT_CATALOG_DATA',
	SET_PRICE_LISTS: 'SET_PRICE_LISTS',
};
export const ADD_CARD_ERROR_MODAL_TYPES = {
	SET_MODAL_DATA: 'SET_MODAL_DATA',
};
export const LINKED_CATALOG = {
	UPDATE_LINKED_CATALOG: 'UPDATE_LINKED_CATALOG',
	UPDATE_LINKED_CATALOG_DATA: 'UPDATE_LINKED_CATALOG_DATA',
};
export const WISHLIST_ACTION_TYPES = {
	REMOVE_WISHLIST: 'REMOVE_WISHLIST',
	CREATE_NEW_SELF_WISHLIST: 'CREATE_NEW_SELF_WISHLIST',
	REMOVE_SELF_WISHLIST: 'REMOVE_SELF_WISHLIST',
	UPDATE_ALL_SELF_WISHLIST: 'UPDATE_ALL_SELF_WISHLIST',
	CREATE_NEW_BUYER_WISHLIST: 'CREATE_NEW_BUYER_WISHLIST',
	REMOVE_BUYER_WISHLIST: 'REMOVE_BUYER_WISHLIST',
	UPDATE_ALL_BUYER_WISHLIST: 'UPDATE_ALL_BUYER_WISHLIST',
	CLEAR_BUYER_WISHLIST: 'CLEAR_BUYER_WISHLIST',
	CLEAR_SELF_WISHLIST: 'CLEAR_SELF_WISHLIST',
	CLEAR_WISHLIST: 'CLEAR_WISHLIST',
	UPDATE_BUYER_WISHLIST_LOADER: 'UPDATE_BUYER_WISHLIST_LOADER',
	UPDATE_SELF_WISHLIST_LOADER: 'UPDATE_SELF_WISHLIST_LOADER',
	UPDATE_SELF_WISHLIST: 'UPDATE_SELF_WISHLIST',
	UPDATE_BUYER_WISHLIST: 'UPDATE_BUYER_WISHLIST',
	UPDATE_CUSTOMER_WISHLIST_DATA: 'UPDATE_CUSTOMER_WISHLIST_DATA',
};
