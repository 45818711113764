import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useCallback, useEffect, useRef, useState } from 'react';
import ImageLinks from 'src/assets/images/ImageLinks';
import Icon from '../../atoms/Icon/Icon';
import Image from '../../atoms/Image/Image';
import styles from './Sidebar.module.css';
import Can from 'src/casl/Can';
import SidebarTab from './SidebarTab';
import { size } from 'lodash';
// import SidebarMenu from './SidebarMenu';

export interface SidebarProps {
	sidebarItems: SidebarItemsProps[];
	logo?: any;
	bottomActions: SidebarItemsProps[];
	active?: number | string;
}

export interface SidebarItemsProps {
	id: number | string;
	priority?: number;
	icon: string;
	title: string;
	link: string;
	slug?: string;
	type?: string;
	onIconClick: () => any;
}

const Sidebar = ({ logo, sidebarItems, bottomActions, active }: SidebarProps) => {
	const [expand, setExpand] = useState(false);
	const [width, setWidth] = useState<string>('5.6rem');
	const [activePage, setActivePage] = useState(active);
	const [expand_tab, set_expand_tab] = useState<string[]>([]);

	const ref = useRef<HTMLDivElement>(null);

	let bottom_icon_style = {
		display: 'flex',
		justifyContent: 'center',
	};

	const validate_active = (link: string) => {
		const url_location = window.location.pathname;
		return url_location.includes(link) && link !== '/';
	};

	useEffect(() => {
		setActivePage(active);
	}, [active]);

	// Handle to check outside sidebar check
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (ref.current && !ref.current.contains(event.target as Node)) {
				// Handle the click outside event here
				setExpand((state) => {
					if (state) {
						return false;
					} else return state;
				});
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	useEffect(() => {
		if (expand) {
			setWidth('24.6rem');
		} else {
			setWidth('7rem');
		}
	}, [expand]);

	const sortedSidebarItems = sidebarItems?.sort((a: any, b: any) => a.priority - b.priority);

	const getParentId = useCallback((arr: any, childId: string | number) => {
		const parentObj = arr?.find((obj: any) => obj?.children && obj?.children?.some((childObj: any) => childObj?.id === childId));
		return parentObj;
	}, []);

	const handleClick = (item: any, set_active: any = true): any => {
		const parent = getParentId(sidebarItems, item?.id);
		const change_active = validate_active(item?.link);
		if (set_active && change_active) {
			setActivePage(parent?.id ?? item?.id);
		}
		setExpand(false);
	};

	const handleSidebarIconClick = (event: any, item: any) => {
		event?.stopPropagation();
		if (size(item?.children) === 0) {
			handleClick(item, item?.set_active);
			item?.onIconClick();
		}
	};

	const handle_side_bar_toggle = (tab_id: string) => {
		set_expand_tab((prev) => {
			if (prev.includes(tab_id)) {
				return prev.filter((prev_id: string) => tab_id !== prev_id);
			}
			return [/*...prev,*/ tab_id];
		});
	};

	const handle_side_bar_add = (tab_id: string) => {
		set_expand_tab(() => [/*...prev,*/ tab_id]);
	};

	const handle_side_bar_remove = (tab_id: string) => {
		set_expand_tab((prev) => prev.filter((data_id) => data_id !== tab_id));
	};

	return (
		<Box
			ref={ref}
			sx={{
				background: '#fff',
				borderRadius: expand ? '0 8px 8px 0' : 0,
				width,
				zIndex: 100,
				position: 'absolute',
				boxShadow: expand ? '0px 4px 40px rgba(0, 0, 0, 0.3)' : 'none',
			}}>
			<Grid
				className={styles.container}
				display={'flex'}
				flexDirection='column'
				justifyContent='space-between'
				alignItems='center'
				rowSpacing={2}>
				<Grid
					sx={{
						height: '4em',
						position: 'sticky',
						top: 0,
						background: '#FFF',
						zIndex: 999,
						marginBottom: '1rem',
						alignItems: 'center',
						display: 'flex',
					}}>
					{expand ? (
						<Grid item>
							<Grid
								container
								direction='row'
								justifyContent='space-between'
								alignItems='center'
								sx={{ flexWrap: 'noWrap', overflow: 'hidden' }}>
								<Grid item ml={-1}>
									<Image width={175} height={25} style={{ marginLeft: '1em', paddingLeft: '14px' }} src={logo} />
								</Grid>
								<Grid item onClick={() => setExpand((state) => !state)} mt={'0.35rem'} ml={'0.35rem'} justifyContent='center'>
									<Icon color='#4F555E' iconName='IconX' size='large' sx={{ cursor: 'pointer', paddingRight: '10px' }} />
								</Grid>
							</Grid>
						</Grid>
					) : (
						<Grid item display='flex' onClick={() => setExpand((state) => !state)} mt={'-0.35rem'} justifyContent='center'>
							<Icon color='#4F555E' iconName='IconMenu2' size='large' sx={{ height: 40, cursor: 'pointer', margin: '0 auto' }} />
						</Grid>
					)}
				</Grid>
				<Grid width={'100%'} mt='-1.5rem' className={styles['custom-scrollbar']}>
					{sortedSidebarItems?.map((item: any) => {
						let style: any = {
							margin: '1rem 0',
						};
						if (!expand) {
							style = {
								...style,
								display: 'flex',
								justifyContent: 'center',
							};
						}

						if (!item.slug)
							return (
								<SidebarTab
									item={item}
									expand={expand}
									handleSidebarIconClick={handleSidebarIconClick}
									style={style}
									activePage={activePage}
									handleClick={handleClick}
									expand_tab={expand_tab}
									handle_side_bar_add={handle_side_bar_add}
									handle_side_bar_remove={handle_side_bar_remove}
									handle_side_bar_toggle={handle_side_bar_toggle}
								/>
							);
						return (
							<Can I={item.slug} a={item.type}>
								<SidebarTab
									item={item}
									expand={expand}
									handleSidebarIconClick={handleSidebarIconClick}
									style={style}
									activePage={activePage}
									handleClick={handleClick}
									expand_tab={expand_tab}
									handle_side_bar_add={handle_side_bar_add}
									handle_side_bar_remove={handle_side_bar_remove}
									handle_side_bar_toggle={handle_side_bar_toggle}
								/>
							</Can>
						);
					})}
				</Grid>
				<Grid
					className={styles.footer}
					mt={expand ? '-1rem' : '0rem'}
					display={'flex'}
					rowSpacing={'auto'}
					gap={'12px'}
					flexDirection='column'
					style={!expand ? bottom_icon_style : {}}
					justifyContent='space-between'
					alignItems='center'>
					<Grid marginTop={'6px'}></Grid>
					{bottomActions?.map((item: any) => {
						let style: any = {};
						if (!expand) {
							style = {
								...style,
								display: 'flex',
								justifyContent: 'center',
							};
						}

						if (!item.slug)
							return (
								<SidebarTab
									item={item}
									expand={expand}
									handleSidebarIconClick={handleSidebarIconClick}
									style={style}
									activePage={activePage}
									handleClick={handleClick}
									expand_tab={expand_tab}
									handle_side_bar_add={handle_side_bar_add}
									handle_side_bar_remove={handle_side_bar_remove}
									handle_side_bar_toggle={handle_side_bar_toggle}
								/>
							);
						return (
							<Can I={item.slug} a={item.type}>
								<SidebarTab
									item={item}
									expand={expand}
									handleSidebarIconClick={handleSidebarIconClick}
									style={style}
									activePage={activePage}
									handleClick={handleClick}
									expand_tab={expand_tab}
									handle_side_bar_add={handle_side_bar_add}
									handle_side_bar_remove={handle_side_bar_remove}
									handle_side_bar_toggle={handle_side_bar_toggle}
								/>
							</Can>
						);
					})}
				</Grid>
			</Grid>
		</Box>
	);
};

Sidebar.defaultProps = {
	logo: ImageLinks.LogoWithText,
	active: 1,
};

export default Sidebar;
