import _ from 'lodash';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Grid, Icon } from 'src/common/@the-source/atoms';

interface Props {
	handle_upload: (file: any) => void;
	set_show_toast?: any;
	title?: string;
	sub_title?: string;
	custom_component?: React.ReactNode;
	accepted_files?: any;
	size?: number;
	set_size_error?: (value: string) => void;
}

function DropZone({
	handle_upload,
	set_show_toast,
	sub_title = 'Ex: Business Cards, Brochures etc',
	title = 'Add attachments',
	custom_component,
	accepted_files = '',
	size = 1000,
	set_size_error,
}: Props) {
	const onDrop = useCallback(
		(acceptedFiles: any, rejectedFiles: any) => {
			if (set_size_error && _.some(acceptedFiles, (file: any) => file?.size > size * 1000)) {
				set_size_error(`Max file size - ${size / 1000}MB`);
				return;
			}
			handle_upload(acceptedFiles);

			if (!_.isEmpty(rejectedFiles)) {
				const file_name = _.get(rejectedFiles, '[0].file.name');
				const error_message = _.get(rejectedFiles, '[0].errors[0].message');
				set_show_toast({ state: true, title: `${file_name} Upload failed`, sub_title: error_message });
			}
		},
		[set_size_error],
	);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

	return (
		<div {...getRootProps()} style={{ display: 'flex', flex: 1, position: 'relative' }}>
			<input {...getInputProps()} accept={accepted_files} />
			{isDragActive ? (
				<p>Drop the files here ...</p>
			) : (
				<>
					{custom_component ? (
						custom_component
					) : (
						<Grid
							container
							alignItems={'center'}
							justifyContent={'space-between'}
							border={'1px dashed #D1D6DD'}
							className='uploaded-item'
							flexWrap={'nowrap'}
							sx={{
								cursor: 'pointer',
								position: 'relative',
							}}
							p={2}>
							<Grid>
								<span style={{ fontSize: 14, color: '#A3A3A3' }}>{title}</span>
								<br></br>
								<span style={{ fontSize: 14, color: '#A3A3A3' }}>{sub_title}</span>
							</Grid>
							<Grid textAlign={'right'}>
								<Icon color='#16885F' className='upload-file-icon' iconName='IconPhotoPlus' />
							</Grid>
						</Grid>
					)}
				</>
			)}
		</div>
	);
}

export default DropZone;
