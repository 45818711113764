import { useEffect } from 'react';
import size from 'lodash/size';
import { find, isEmpty } from 'lodash';
import { useLocation } from 'react-router-dom';
import { Grid, Tooltip } from '../../atoms';
import SidebarIcon from '../../atoms/SidebarIcon';
import SidebarTooltip from './SidebarTooltip';
import { colors } from 'src/utils/theme';

const SidebarTab = ({
	item,
	expand,
	handleSidebarIconClick,
	style,
	activePage,
	handleClick,
	expand_tab,
	handle_side_bar_add,
	handle_side_bar_remove,
	handle_side_bar_toggle,
}: any) => {
	const location = useLocation();

	const validate_active = (link: string) => {
		const url_location = window.location.pathname;
		return url_location.includes(link) && link !== '/';
	};

	useEffect(() => {
		if (item && expand) {
			const is_children_active = find(item?.children ?? [], (child_data: any) => validate_active(child_data?.link));
			if (!isEmpty(is_children_active) && !expand_tab.includes(item?.id)) {
				handle_side_bar_add(String(item?.id));
			} else if (isEmpty(is_children_active) && expand_tab.includes(item?.id)) {
				handle_side_bar_remove(String(item?.id));
			}
		}
	}, [expand, item, location.pathname]);

	return (
		<Grid
			onClick={(event: any) => {
				handleSidebarIconClick && handleSidebarIconClick(event, item);
				if (expand) {
					handle_side_bar_toggle(String(item?.id));
				}
			}}
			item
			xs={12}
			key={item?.id}
			style={{ width: '100%', ...style }}>
			<Tooltip
				componentsProps={
					size(item?.children) > 0
						? {
								tooltip: {
									sx: {
										width: '210px',
										padding: '8px',
										display: 'flex',
										flexDirection: 'column',
										backgroundColor: colors.white,
										boxShadow: '0px 4px 40px 0px #00000014',
									},
								},
						  }
						: {}
				}
				id={item?.id.toString()}
				title={
					size(item?.children) > 0 ? (
						<SidebarTooltip childItems={item?.children} handleClick={handleClick} title={item?.title} />
					) : (
						item?.title
					)
				}
				placement={size(item?.children) > 0 ? 'right-start' : 'right'}
				textStyle={{ fontSize: '1.2rem' }}
				disableHoverListener={expand}
				disableFocusListener
				disableTouchListener
				arrow={size(item?.children) === 0}>
				<div>
					<SidebarIcon
						size='large'
						text={expand ? item?.title : ''}
						hasChildren={item?.children?.length > 0}
						expandIcon={expand_tab.includes(item?.id)}
						name={item?.icon}
						active_name={item?.icon_active}
						key={item?.id}
						clicked={item?.id === activePage && (!expand || size(item?.children) === 0)}
						fullWidth={expand}
						is_icon={item?.is_icon ?? true}
					/>
				</div>
			</Tooltip>
			<Grid sx={{ boxShadow: 'none' }}>
				{item?.children?.length > 0 &&
					expand &&
					expand_tab.includes(item?.id) &&
					item.children.map((child: any) => {
						const is_clicked = validate_active(child?.link);
						return (
							<Grid
								justifyContent='space-between'
								onClick={() => {
									handleClick && handleClick(child, child?.set_active);
									child?.onIconClick();
								}}
								key={child?.id}>
								<SidebarIcon
									text={expand ? child?.title : ''}
									hasChildren={child?.children?.length > 0}
									name={child?.icon}
									active_name={child?.icon_active}
									key={child?.id}
									clicked={is_clicked}
									fullWidth={expand}
									is_icon={child?.is_icon ?? true}
									is_child={true}
								/>
							</Grid>
						);
					})}
			</Grid>
		</Grid>
	);
};

export default SidebarTab;
