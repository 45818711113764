const TIMEZONES = [
	{
		label: '(GMT -3:30) Newfoundland Standard Time (Canada)',
		value: 'America/St_Johns',
	},
	{
		label: '(GMT -4:00) Atlantic Standard Time',
		value: 'America/Puerto_Rico',
	},
	{
		label: '(GMT -5:00) Eastern Standard Time',
		value: 'America/New_York',
		is_default: true,
	},
	{
		label: '(GMT +0:00) Coordinated Universal Time (UTC)',
		value: 'UTC',
	},
	{
		label: '(GMT -6:00) Central Standard Time',
		value: 'America/Chicago',
	},
	{
		label: '(GMT -7:00) Mountain Standard Time',
		value: 'America/Denver',
	},
	{
		label: '(GMT -8:00) Pacific Standard Time',
		value: 'America/Los_Angeles',
	},
	{
		label: '(GMT -9:00) Alaska Standard Time',
		value: 'America/Anchorage',
	},
	{
		label: '(GMT -10:00) Hawaii Standard Time',
		value: 'Pacific/Honolulu',
	},
	{
		label: '(GMT -11:00) Samoa Standard Time',
		value: 'Pacific/Pago_Pago',
	},
	{
		label: '(GMT +5:30) Indian Standard Time',
		value: 'Asia/Kolkata',
	},
];

export default TIMEZONES;
