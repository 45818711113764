import { Alert } from '@mui/material';
import { t } from 'i18next';
import _ from 'lodash';
import React, { useState } from 'react';
import { Box, CustomInput, Grid, Icon } from 'src/common/@the-source/atoms';
import CustomText from 'src/common/@the-source/CustomText';
import styles from '../customproduct.module.css';
import { handle_subtext } from '../helper';
import ModifierField from '../ModifierField';
import { secondary } from 'src/utils/light.theme';
import NoOption from './NoOption';
import { colors } from 'src/utils/theme';
import { MAX_TEXT_VALIDATION } from '../constants';
import { get_short_name } from 'src/screens/Wishlist/utils';

interface CustomValType {
	[key: string]: number | string;
}

interface CustomProductBodyProps {
	data: any;
	custom_val: CustomValType;
	errors: any;
	done_click: boolean;
	handle_values: (custom_data: any) => void;
	handle_error: (error: any) => void;
	is_edit?: boolean;
	currency: string;
}

const dividerStyle: React.CSSProperties = {
	height: '1px',
	background: 'repeating-linear-gradient(90deg,#D1D6DD 0 4px,#0000 0 8px)',
	margin: '28px 0',
	marginBottom: '2.8rem',
};

const CustomProductBody = ({
	data,
	custom_val,
	errors,
	done_click,
	handle_values,
	handle_error,
	is_edit,
	currency,
}: CustomProductBodyProps) => {
	const sorted_data: any = _.sortBy(data, (item: any) => item?.priority);
	const [search, set_search] = useState<any>({});

	const handle_search = (search_string: string, key: string) => {
		set_search((prev: {}) => ({ ...prev, [key]: search_string?.trim() }));
	};

	return (
		<Grid className={styles.custom_product_drawer_body}>
			{sorted_data?.map((curr: any, ind: number) => {
				// eslint-disable-next-line react-hooks/rules-of-hooks
				const _select_value = (() => {
					const selected_names = custom_val?.[curr?.id];
					const is_multi_select = curr?.type === 'Multi Select';

					if (is_multi_select && !_.isEmpty(selected_names) && typeof selected_names === 'string') {
						const names_list = selected_names?.split(',');

						let truncated_names = [];
						let total_length = 0;
						for (const name of names_list) {
							const new_length = total_length + name?.length + (truncated_names?.length > 0 ? 2 : 0);
							if (new_length > 60) break;
							truncated_names?.push(name);
							total_length = new_length;
						}

						const more_count = names_list?.length - truncated_names?.length;
						return more_count > 0 ? `${truncated_names?.join(', ')}... +${more_count} more` : truncated_names?.join(', ');
					} else if (!is_multi_select && selected_names) {
						return _.get(_.find(curr?.options, { name: selected_names }), 'display_name', selected_names);
					}

					return null;
				})();

				const err = !errors[curr?.id]?.valid && done_click;

				const filter_values =
					search[curr?.name] || ''
						? _.filter(curr?.options, (item) => item.name.toLowerCase().includes(search[curr?.name].toLowerCase() || ''))
						: curr?.options;

				const filter_values_length = filter_values.length;

				return (
					<React.Fragment key={ind}>
						<Box className={styles.drawer_body_container}>
							<Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
								<CustomText type='H3' style={{ color: '#666' }}>
									{curr?.name ?? ''}:
								</CustomText>
								{!custom_val[curr?.id] ? (
									<Box
										className={styles.drawer_required_text}
										sx={{
											background: curr?.mandatory ? '#FBEDE7' : '#F2F4F7',
										}}>
										<CustomText type='Body' style={{ color: curr?.mandatory ? '#D74C10' : '#676D77' }}>
											{curr?.mandatory
												? t('CustomProduct.CustomText.Required')
												: curr?.type === 'Text'
												? t('CustomProduct.CustomText.EnterText')
												: t('CustomProduct.CustomText.SelectOptions')}
										</CustomText>
									</Box>
								) : (
									<CustomText type='Title' className='modifier_select_value'>
										{curr?.type === 'Text' ? get_short_name(_select_value, 32) : _select_value}
									</CustomText>
								)}
							</Box>
							{curr?.type === 'Multi Select' || curr.type === 'Counter' ? (
								<> {handle_subtext(curr, errors, done_click, custom_val, curr?.display_type === 'Swatch')}</>
							) : (
								curr?.type !== 'Text' &&
								curr?.mandatory && (
									<CustomText type='Body' style={{ marginTop: '1rem', color: err ? '#D74C10' : '#4F555E' }}>
										{t('CustomProduct.CustomText.SelectOption')}
									</CustomText>
								)
							)}
							{curr.type === 'Counter' && (
								<CustomText type='Body' style={{ marginTop: '1rem', color: '#4F555E' }}>
									${curr?.options[0]?.price || 0}/unit
								</CustomText>
							)}

							{curr?.type === 'Text' && (
								<>
									{curr?.max_selection_quantity !== curr?.min_selection_quantity && (
										<CustomText color={err ? colors.red : colors.secondary_text} style={{ marginTop: '8px' }}>
											{t('CustomProduct.Modal.LimitCopy', {
												min: curr?.min_selection_quantity ?? 0,
												max:
													curr?.max_selection_quantity > curr?.min_selection_quantity ? curr?.max_selection_quantity : MAX_TEXT_VALIDATION,
											})}
										</CustomText>
									)}
								</>
							)}

							{['Swatch', 'Radio Button', 'Chip'].includes(curr?.display_type) && curr?.options.length >= 7 && (
								<>
									<CustomInput
										size='small'
										fullWidth
										inputType='search'
										startIcon={<Icon iconName='IconSearch' color={secondary[800]} />}
										onChange={(e) => handle_search(e.target.value, curr?.name)}
										placeholder='Search'
										input_style={{ margin: '1rem 0rem' }}
										allowClear>
										{t('Common.FilterComponents.Search')}
									</CustomInput>
									{search[curr?.name] && <CustomText color='#525252'>Showing {filter_values_length} results</CustomText>}
								</>
							)}

							{filter_values_length > 0 ? (
								<ModifierField
									dType={curr?.type}
									modifier={curr?.display_type}
									values={filter_values}
									data={curr}
									options={custom_val[curr?.id]}
									id={curr?.id}
									handleValues={handle_values}
									handleError={handle_error}
									searchString={search[curr?.name] || ''}
									is_edit={is_edit}
									currency={currency}
									is_error={(curr?.mandatory && err) || err}
								/>
							) : (
								<NoOption image={true} />
							)}

							{curr?.disclaimers && (
								<Alert
									severity='info'
									icon={<Icon sx={{ mr: -1, opacity: 0.8 }} iconName='IconInfoCircle' />}
									sx={{ marginTop: '15px', width: '100%', height: '40px', display: 'flex', alignItems: 'center', color: '#F0F6FF' }}>
									<CustomText type='Body'>{curr?.disclaimers}</CustomText>
								</Alert>
							)}
						</Box>
						{ind !== data?.length - 1 && <div style={dividerStyle}></div>}
					</React.Fragment>
				);
			})}
		</Grid>
	);
};
export default CustomProductBody;
