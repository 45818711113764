const Events = {
	APP_LAUNCHED: 'app_launched',
	LOGIN_COMPLETED: 'login_completed',
	LOGIN_FAILED: 'login_failed',
	RESET_LINK_SUCCESS: 'reset_link_success',
	RESET_LINK_FAILED: 'reset_link_failed',
	LOGOUT_BUTTON_CLICKED: 'logout_button_clicked',
	CART_CLICKED: 'cart_clicked',
	CATALOG_SELECTION_CANCELLED: 'catalog_selection_cancelled',
	CATALOG_SWITCHER_CLICKED: 'catalog_switcher_clicked',
	CATALOG_SELECTED: 'catalog_selected',
	ADD_TO_CART_CLICKED: 'add_to_cart_clicked',
	SORT_OPTION_CLICKED: 'sort_option_clicked',
	SEARCH_EXECUTED: 'search_executed',
	SEARCH_BAR_CLICKED: 'search_bar_clicked',
	PRODUCT_CARD_CLICKED: 'product_card_clicked',
	VIEW_SIMILAR_CLICKED: 'view_similar_clicked',
	CUSTOMER_SELECTED: 'customer_selected',
	CUSTOMER_SELECTION_CANCELLED: 'customer_selection_cancelled',
	RESET_LINK_SENT_CLICKED: 'reset_link_sent_clicked',
	BACK_TO_LOGIN_BUTTON_CLICKED: 'back_to_login_button_clicked',
	LOGIN_BUTTON_CLICKED: 'login_button_clicked',
	BOOK_DEMO_CLICKED: 'book_demo_clicked',
	FORGOT_PASSWORD_CLICKED: 'forgot_password_clicked',
	PDF_PREVIEW_CLICKED: 'pdf_preview_clicked',
	PDF_DOWNLOAD_CLICKED: 'pdf_download_clicked',
	SAVE_NOTE_CLICKED: 'save_note_clicked',
	DELETE_CART_OPTION_CLICKED: 'delete_cart_option_clicked',
	ADD_MORE_CUSTOM_LINE_CLICKED: 'add_more_custom_line_clicked',
	CONFIRM_DISCOUNT_CLICKED: 'confirm_discount_clicked',
	ADD_CUSTOM_LINE_CLICKED: 'add_custom_line_clicked',
	ADD_PRODUCT_NOTE_OPTION_CLICKED: 'add_product_note_option_clicked',
	OFFER_DISCOUNT_OPTION_CLICKED: 'offer_discount_option_clicked',
	TAB_CLICKED: 'tab_clicked',
	REPEAT_DOCUMENT_CLICKED: 'repeat_document_clicked',
	EDIT_DOCUMENT_CLICKED: 'edit_document_clicked',
	DUPLICATE_DOCUMENT_CLICKED: 'duplicate_document_clicked',
	UPDATE_ORDER_CLICKED: 'update_order_clicked',
	ADD_SIGNATURE_CLICKED: 'add_signature_clicked',
	EDIT_SIGNATURE_CLICKED: 'edit_signature_clicked',
	TEAR_SHEET_BUTTON_CLICKED: 'tear_sheet_button_clicked',
	CANCEL_TEAR_SHEET_BUTTON_CLICKED: 'cancel_tear_sheet_button_clicked',
	VIEW_ALL_BUTTON_CLICKED: 'view_all_button_clicked',
	CATEGORY_CLICKED: 'category_clicked',
	COLLECTION_CLICKED: 'collection_clicked',
	ABANDONED_CART_CHECKOUT_CLICKED: 'abandoned_cart_checkout_clicked',
	ABANDONED_CART_MARKED_VIEWED: 'abandoned_cart_marked_viewed',
	ABANDONED_CART_ADD_EDIT_COMMENT: 'abandoned_cart_add_edit_comment',
};

export default Events;
